import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Check from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { ReactNode } from 'react';

interface IAdminPickerCardProps {
  selected: boolean;
  title: string;
  imgUrl?: string;
  subTitle: string;
  subSubTitle: string;
  disabled?: boolean;
  secondaryActionType: 'delete' | 'edit';
  secondaryAction: () => void;
  content?: ReactNode;
}

function PickerElement(props: IAdminPickerCardProps) {
  const {
    selected,
    title,
    subTitle,
    imgUrl,
    disabled,
    secondaryActionType,
    secondaryAction,
    content,
    subSubTitle,
  } = props;

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: '100%',
        cursor: disabled ? 'not-allowed' : 'pointer',
        position: 'relative',
        opacity: disabled ? 0.3 : 1,
      }}
    >
      {!selected && (
        <Box
          width={25}
          height={25}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: 'secondary.light' }}
          bottom={-10}
          right={-10}
          position="absolute"
        >
          {secondaryActionType === 'edit' ? (
            <Edit
              sx={{
                color: 'background.paper',
                width: '80%',
              }}
              onClick={e => {
                e.stopPropagation();
                secondaryAction();
              }}
            />
          ) : (
            <Delete
              sx={{
                color: 'background.paper',
                width: '80%',
              }}
              onClick={e => {
                e.stopPropagation();
                secondaryAction();
              }}
            />
          )}
        </Box>
      )}
      {selected && (
        <>
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'secondary.light',
              opacity: 0.3,
            }}
          ></Box>
          <Box
            width={25}
            height={25}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: 'secondary.light' }}
            top={-10}
            right={-10}
            position="absolute"
          >
            <Check
              sx={{
                color: '#000',
                width: '100%',
              }}
            />
          </Box>
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1}
        padding={1}
        height="100%"
      >
        {imgUrl && (
          <img
            src={imgUrl}
            style={{
              maxHeight: 200,
              maxWidth: 200,
            }}
            alt={title}
          />
        )}
        <Typography align="center">
          <b>{title}</b>
        </Typography>
        <Typography align="center">{subTitle}</Typography>
        <Typography align="center">{subSubTitle}</Typography>
        {content ? content : ''}
      </Box>
    </Paper>
  );
}

export default PickerElement;
