import { useState } from 'react';
import purple from '@mui/material/colors/purple';
import Container from '@mui/material/Container';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import SessionsTable from './components/SessionTable/SessionTable';
import { IconButton, Typography } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Box from '@mui/system/Box';
import SignInScreen from './components/misc/SignInScreen';
import GeneralMngForm from './components/SessionTable/GeneralMngForm';

function App() {
  const [isLightTheme, setIsLightTheme] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState<undefined | boolean>(undefined);

  const theme = createTheme({
    palette: {
      mode: isLightTheme ? 'light' : 'dark',
      primary: {
        main: '#00e5ff',
      },
      secondary: purple,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: 'background.paper', minHeight: '100%', pb: 2 }}
      >
        <IconButton
          color="primary"
          onClick={() => {
            setIsLightTheme(!isLightTheme);
          }}
          sx={{ position: 'absolute', top: 10, left: 20 }}
        >
          {isLightTheme ? (
            <Brightness4Icon fontSize="large" />
          ) : (
            <Brightness7Icon fontSize="large" />
          )}
        </IconButton>
        <SignInScreen isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />
        {isSignedIn && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <Typography
                variant="h3"
                align="center"
                color="primary"
                mt={2}
                mb={5}
              >
                GENVISION
              </Typography>
              <GeneralMngForm />
              <Typography
                variant="h5"
                align="center"
                color="primary"
                mt={3}
                mb={1}
              >
                Votazioni
              </Typography>
              <SessionsTable />
            </Box>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
