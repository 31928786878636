import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../fbConfig';
import {
  ISession,
  ESessionOpenTarget,
  ESessionActivationType,
  ISchedule,
} from '../../interfaces';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';
import CreateSessionForm from '../CreateSessionForm/CreateSessionForm';

import SessionTableRow from './SessionTableRow';
import useTimeTrigger from '../../hooks/useTimeTrigger';
import { SESSIONS_COLL_ID } from '../../constants';
import { stickyColumnSx } from '../../styleUtils';

export const isNowWithinSchedule = (schedule: ISchedule, nowS: number) => {
  return nowS >= schedule.start && nowS < schedule.end;
};

interface Obj<T> {
  [key: string]: T;
}

function mapObj<TValue, TResult>(
  obj: Obj<TValue>,
  valSelector: (val: TValue, obj: Obj<TValue>) => TResult
) {
  const ret = {} as Obj<TResult>;
  for (const key of Object.keys(obj)) {
    const retVal = valSelector.call(null, obj[key], obj);
    ret[key] = retVal;
  }
  return ret;
}

const getOpenSessions = (sessions: ISession[], nowS: number) =>
  Object.values(ESessionOpenTarget)
    .map(openTarget => [
      openTarget,
      sessions
        .filter(ses =>
          ses[openTarget].type === ESessionActivationType.onPrompt
            ? ses[openTarget].open
            : isNowWithinSchedule(ses[openTarget].open as ISchedule, nowS)
        )
        .map(ses => ses.id),
    ])
    .reduce(
      (acc, [openTarget, val]) => ({
        ...acc,
        [openTarget as ESessionOpenTarget]: val,
      }),
      {} as { [openTarget in ESessionOpenTarget]: string[] }
    );

function SessionsTable() {
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [createSessionOpen, setCreateSessionOpen] = useState(false);

  const [timeTrigger, triggerAt] = useTimeTrigger(true);

  useEffect(() => {
    // trigger next time to check open sessions

    const minFutureDateTime = Math.min(
      ...sessions
        .flatMap(ses =>
          Object.values(ESessionOpenTarget).flatMap(openTarget =>
            ses[openTarget].type === ESessionActivationType.onSchedule
              ? [
                  (ses[openTarget].open as ISchedule).start,
                  (ses[openTarget].open as ISchedule).end,
                ]
              : []
          )
        )
        .filter(time => time > Date.now() / 1000)
    );

    if (minFutureDateTime !== Infinity) {
      const clearTimer = triggerAt(minFutureDateTime);
      return () => {
        clearTimer();
      };
    }
  }, [sessions, triggerAt]);

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      collection(db, SESSIONS_COLL_ID),
      snaps => {
        setSessions(
          snaps.docs.map(doc => ({ ...doc.data(), id: doc.id } as ISession))
        );
      },
      err => {
        console.error('ERROR WHEN LISTENING TO SESSIONS', err);
      }
    );
    return () => {
      clearOnSnapshot();
    };
  }, []);

  const [openSessionsPerTarget, setOpenSessions] = useState(
    getOpenSessions(sessions, timeTrigger)
  );

  useEffect(() => {
    setOpenSessions(getOpenSessions(sessions, timeTrigger));
  }, [sessions, timeTrigger]);

  const someSessionOpen = mapObj(openSessionsPerTarget, openSessions =>
    sessions.some(ses => openSessions.includes(ses.id))
  ) as { [openTarget in ESessionOpenTarget]: boolean };

  return (
    <>
      {sessions.length === 0 ? (
        <Typography color="text.primary">
          Non ci sono votazioni, creane una
        </Typography>
      ) : (
        <>
          {Object.values(openSessionsPerTarget).some(
            openSessions => openSessions.length > 1
          ) && (
            <>
              <Typography color="error" variant="h3" align="center" my={2}>
                ATTENZIONE
              </Typography>
              <Typography color="error" variant="h5" align="center" my={2}>
                Ci sono due sessioni attive per lo stesso pubblico, disattivane
                una
              </Typography>
            </>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Descrizione</TableCell>
                  <TableCell align="center">Pubblico</TableCell>
                  <TableCell align="center">Giudici e rappresentanti</TableCell>
                  <TableCell align="center">Biglietti</TableCell>
                  <TableCell align="center">Accrediti</TableCell>
                  <TableCell align="center" sx={{ ...stickyColumnSx('right') }}>
                    Azioni
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.map(session => (
                  <SessionTableRow
                    session={session}
                    key={session.id}
                    isSessionOpen={
                      mapObj(openSessionsPerTarget, openSessions =>
                        openSessions.includes(session.id)
                      ) as { [openTarget in ESessionOpenTarget]: boolean }
                    }
                    otherSessionsOpen={someSessionOpen}
                    isGiftTicketsOpen={session.giftTicketsOpen}
                    otherGiftTicketsOpen={sessions.some(
                      ses => ses.id !== session.id && ses.giftTicketsOpen
                    )}
                    isTicketsOpen={session.ticketsOpen}
                    otherTicketsOpen={sessions.some(
                      ses => ses.id !== session.id && ses.ticketsOpen
                    )}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Fab
        color="primary"
        onClick={() => {
          setCreateSessionOpen(true);
        }}
      >
        <Add />
      </Fab>
      <CreateSessionForm
        open={createSessionOpen}
        setOpen={setCreateSessionOpen}
        existingIds={sessions.map(({ id }) => id)}
      />
    </>
  );
}

export default SessionsTable;
